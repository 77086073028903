import React from 'react'

import './App.css';
import Footer from './Footer';

import NavbarPage from './navbar';

import TermsPolicy from './TermsPolicy';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import QriosAppDetails from './QriosAppDetails';
import Privacy from './Privacy';
import Try from './Try'

function App() {
  return (

    <div className="body">
  
        <Router>

          <NavbarPage/>

          <Switch>
            <Route exact path="/" component={QriosAppDetails} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/try" component={Try} />
            <Route exact path="/termsofservice" component={TermsPolicy}/>
            <Route exact path="/mobileapp" render={() => (<Redirect to="/try" />)} />          
          </Switch>
          
          <div className="footer">
            <Footer/>
          </div>

        </Router>

        </div>
  );
}

export default App;
