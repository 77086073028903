import React, { useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import qrioslogo from '../src/assets/qrios1024X1024.png'

import Drawer from '@material-ui/core/Drawer';

export default function NavbarPage() {

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  return (
    <Router>


    <div className="d-flex flex-row navbar-expand-lg" style={{margin: "1em"}}>

        <div className="d-flex flex-row col-md-10">
          <a href="/">
          <img
            style={{borderRadius: 20, marginRight: 5}}
            className="hover-shadow"
            src={qrioslogo}
            height="40"
            alt=""
          />
          </a>
          <h2 style={{fontFamily: 'Mukta'}}>Qrios</h2>
        </div>

        <button
          className="navbar-toggler w-auto navbar-nav ms-auto mb-2 mb-lg-0"
          type="button"
          onClick={toggleDrawer('right', true)}
          style={{position: 'relative', float: 'right', width: '100%',}}
          >
          <i class="fas fa-bars"></i>
        </button>

        <div className="navbar-collapse collapse">
          <a href="/try"><button type="button" className="btn btn-primary hover-shadow" style={{borderRadius: 30}}>Try for Free</button></a>
        </div>

        <Drawer anchor={"right"} open={state['right']} onClose={toggleDrawer('right', false)}>
          <div style={{margin: '2em'}}>

            <hr/>

            <p>
            <a href="https://testflight.apple.com/join/zHn70h7S" rel="noreferrer" target="_blank" className="text-reset">iOS App</a>
            </p>

            <p>
            <a href="https://play.google.com/apps/testing/com.qriosmobilev1.test" rel="noreferrer" target="_blank" className="text-reset">Android App</a>
            </p>

            <hr/>

          <p>
            <a href="/privacy" class="text-reset">Privacy Policy</a>
          </p>
          <p>
            <a href="/termsofservice" class="text-reset">Terms of Service</a>
          </p>
          <p>
            <a href="mailto:hello@qriosapp.com" class="text-reset">Email Us</a>
          </p>

          <p>
            <a href="/try"><button type="button" className="btn btn-primary hover-shadow" style={{borderRadius: 30}}>Try for Free</button></a>
          </p>

        </div>

        </Drawer>

    </div>

    </Router>

  )
}
