import React from "react";

function Privacy() {
  return (
    <div className="container">
      <h1>
        <b>Privacy Policy</b>
      </h1>
      <p>
        Feenixtek Inc. has built the Qrios app as a Freemium app. This SERVICE
        is provided by Feenixtek Inc. at no cost and is intended for use as is.
        Qrios may feature in-app purchases in later versions.
      </p>
      <p>
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible at Qrios unless otherwise
        defined in this Privacy Policy.
      </p>
      <h3>
        <b>Information Collection and Use</b>
      </h3>
      For a better experience, while using our Service, we may require you to
      provide us with certain personally identifiable information, including but
      not limited to:
      <ul>
        <li>first name</li>
        <li>last name</li>
        <li>email address</li>
        <li>country</li>
        <li>city</li>
        <li>GPS location</li>
        <li>languages spoken</li>
        <li>photos</li>
      </ul>
      <p>
        The personally identifiable information provided will be used to build
        your personal profile including log-in details, connect you with users
        who speak your language, and for security purposes.
      </p>
      <p>
        In order for you to use the in-app features, we will request access to
        your microphone and camera for use in our interactive games. We may also
        request access to your phone’s GPS function to verify your identity for
        security purposes.
      </p>
      <p>
        The app does use third party services that may collect information used
        to identify you.
      </p>
      <p>
        Link to privacy policy of third party service providers used by the app
      </p>
      <ul>
        <li>
          <a href="https://www.google.com/policies/privacy/" target={"_blank"}>
            Google Play Services
          </a>
        </li>

        <li>
          <a
            href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
            target={"_blank"}
          >
            Apple App Store
          </a>
        </li>

        <li>
          <a
            href="https://www.appsflyer.com/legal/privacy-policy/"
            target={"_blank"}
          >
            Apps Flyer
          </a>
        </li>

        <li>
          <a
            href="https://policies.google.com/privacy?hl=en-US"
            target={"_blank"}
          >
            Google Analytics
          </a>
        </li>

        <li>
          <a
            href="https://firebase.google.com/support/privacy"
            target={"_blank"}
          >
            Firebase
          </a>
        </li>

        <li>
          <a href="https://www.agora.io/en/privacy-policy/" target={"_blank"}>
            Agora
          </a>
        </li>
      </ul>
      <h3>
        <b>Log Data</b>
      </h3>
      <p>
        The company want to inform you that whenever you use our Service, in a
        case of an error in the app, we collect data and information (through
        third party products) on your phone called Log Data. This Log Data may
        include information such as your device Internet Protocol (“IP”)
        address, device name, operating system version, the configuration of the
        app when utilizing our Service, the time and date of your use of the
        Service, time spent on the service and other statistics.
      </p>
      <h3>
        <b>Cookies</b>
      </h3>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly. However, the app
        may use third party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>
      <h3>
        <b>Service Providers</b>
      </h3>
      <p>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>

        <li>To provide the Service on our behalf;</li>

        <li>To perform Service-related services; or</li>

        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We like to inform users of this Service that these third parties have
        access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <h3>
        <b>Security</b>
      </h3>
      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>
      <h3>
        <b>Links to Other Sites</b>
      </h3>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <h3>
        <b>Children’s Privacy</b>
      </h3>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to do necessary actions.
      </p>
      <h3>
        <b>Changes to This Privacy Policy</b>
      </h3>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>This policy is effective as of 2021-03-01</p>
      <h3>
        <b>Contact Us</b>
      </h3>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at{" "}
        <a href="hello@qriosapp.com">hello@qriosapp.com</a>
      </p>
    </div>
  );
}

export default Privacy;
