import React from "react";

export default function TermsPolicy() {
  return (
    <div className="container">
      <h1>
        <b>Terms & Conditions</b>
      </h1>

      <p>
        By downloading or using the Qrios app, these terms will automatically
        apply to you – you should make sure therefore that you read them
        carefully before using the app. You’re not allowed to copy, or modify
        the app, any part of the app, or our trademarks in any way. You’re not
        allowed to attempt to extract the source code of the app, and you also
        shouldn’t try to translate the app into other languages, or make
        derivative versions. The app itself, and all the trade marks, copyright,
        database rights and other intellectual property rights related to it,
        still belong to Qrios.
      </p>

      <p>
        Qrios is committed to ensuring that the app is as useful and efficient
        as possible. For that reason, we reserve the right to make changes to
        the app or to charge for its services, at any time and for any reason.
        We will never charge you for the app or its services without making it
        very clear to you exactly what you’re paying for.
      </p>

      <p>
        The Qrios app stores and processes personal data that you have provided
        to us, in order to provide our Service. It’s your responsibility to keep
        your phone and access to the app securely. We therefore recommend that
        you do not jailbreak or root your phone, which is the process of
        removing software restrictions and limitations imposed by the official
        operating system of your device. It could make your phone vulnerable to
        malware/viruses/malicious programs, compromise your phone’s security
        features and it could mean that the Qrios app won’t work properly or at
        all.
      </p>

      <p>
        The app does use third party services that declare their own Terms and
        Conditions.
      </p>

      <p>
        Link to Terms and Conditions of third party service providers used by
        the app
      </p>

      <ul>
        <li>
          <a href="https://www.google.com/policies/privacy/">
            Google Play Services
          </a>
        </li>

        <li>
          <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
            Apple App Store
          </a>
        </li>

        <li>
          <a
            href="https://www.appsflyer.com/legal/privacy-policy/"
            target={"_blank"}
          >
            Apps Flyer
          </a>
        </li>

        <li>
          <a
            href="https://policies.google.com/privacy?hl=en-US"
            target={"_blank"}
          >
            Google Analytics
          </a>
        </li>

        <li>
          <a
            href="https://firebase.google.com/support/privacy"
            target={"_blank"}
          >
            Firebase
          </a>
        </li>

        <li>
          <a href="https://www.agora.io/en/terms-of-service/" target={"_blank"}>
            Agora
          </a>
        </li>
      </ul>

      <p>
        You should be aware that there are certain things that Qrios will not
        take responsibility for. Certain functions of the app will require the
        app to have an active internet connection. The connection can be Wi-Fi,
        or provided by your mobile network provider, but Qrios cannot take
        responsibility for the app not working at full functionality if you
        don’t have access to Wi-Fi, and you don’t have any of your data
        allowance left.
      </p>

      <p>
        If you’re using the app outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the app, or other third party charges. In using the app,
        you’re accepting responsibility for any such charges, including roaming
        data charges if you use the app outside of your home territory (i.e.
        region or country) without turning off data roaming. If you are not the
        bill payer for the device on which you’re using the app, please be aware
        that we assume that you have received permission from the bill payer for
        using the app.
      </p>

      <p>
        Along the same lines, Qrios cannot always take responsibility for the
        way you use the app i.e. You need to make sure that your device stays
        charged – if it runs out of battery and you can’t turn it on to avail
        the Service, Qrios cannot accept responsibility.
      </p>

      <p>
        With respect to Qrios’s responsibility for your use of the app, when
        you’re using the app, it’s important to bear in mind that although we
        endeavour to ensure that it is updated and correct at all times, we do
        rely on third parties to provide information to us so that we can make
        it available to you. Qrios accepts no liability for any loss, direct or
        indirect, you experience as a result of relying wholly on this
        functionality of the app.
      </p>

      <p>
        At some point, we may wish to update the app. The app is currently
        available on Android and iOS – the requirements for system(and for any
        additional systems we decide to extend the availability of the app to)
        may change, and you’ll need to download the updates if you want to keep
        using the app. Qrios does not promise that it will always update the app
        so that it is relevant to you and/or works with the Android or iOS
        version that you have installed on your device. However, you promise to
        always accept updates to the application when offered to you, We may
        also wish to stop providing the app, and may terminate use of it at any
        time without giving notice of termination to you. Unless we tell you
        otherwise, upon any termination, (a) the rights and licenses granted to
        you in these terms will end; (b) you must stop using the app, and (if
        needed) delete it from your device.
      </p>

      <h3>
        <b>Conditions of Use</b>
      </h3>

      <p>
        <b>User Conduct</b>: You are solely responsible for all audio, code,
        video, images, information, data, text, software, music, sound,
        photographs, graphics, messages or other materials (“content”) that you
        upload, post, publish or display (hereinafter, “upload”) or email or
        otherwise use via the Service. The following are examples of the kind of
        content and/or use that is illegal or prohibited by Qrios. Qrios
        reserves the right to investigate and take appropriate legal action
        against anyone who, in Qrios’s sole discretion, violates this provision,
        including without limitation, suspending or terminating the account of
        such violators and reporting you to the law enforcement authorities. You
        agree to not use the Service to:
        <ol>
          <br />

          <li>
            engage in any conversation that
            <ol type="i">
              <li>
                infringes any intellectual property or other proprietary rights
                of any party
              </li>
              <li>poses or creates a privacy or security risk to any person</li>
              <li>
                constitutes unsolicited or unauthorized advertising, promotional
                materials, commercial activities and/or sales, “junk mail,”
                “spam,” “chain letters,” “pyramid schemes,” “contests,”
                “sweepstakes,” or any other form of solicitation
              </li>
              <li>
                is unlawful, harmful, threatening, abusive, harassing, tortious,
                excessively violent, defamatory, vulgar, obscene, pornographic,
                libelous, invasive of another’s privacy, hateful racially,
                ethnically or otherwise objectionable
              </li>
              <li>
                in the sole judgment of Qrios, is objectionable or which
                restricts or inhibits any other person from using or enjoying
                the Service, or which may expose Qrios or its users to any harm
                or liability of any type
              </li>
            </ol>
          </li>

          <br />

          <li>
            record any portion of a conversation without the express written
            consent of all of the players involved
          </li>

          <br />

          <li>
            share information (on Qrios or elsewhere) that was discussed
            confidentially in the app
          </li>

          <br />

          <li>
            interfere with or disrupt the Service or servers or networks
            connected to the Service, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Service; or
          </li>

          <br />

          <li>
            violate any applicable local, state, national or international law,
            or any regulations having the force of law;
          </li>

          <br />

          <li>
            impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity;
          </li>

          <br />

          <li>solicit personal information from anyone under the age of 18;</li>

          <br />

          <li>
            harvest or collect email addresses or other contact information of
            other users from the Service by electronic or other means for the
            purposes of sending unsolicited emails or other unsolicited
            communications;
          </li>

          <br />

          <li>
            advertise or offer to sell or buy any goods or services for any
            business purpose that is not specifically authorized;
          </li>

          <br />

          <li>
            promote or aid in the building of a competitive product or service,
            copy the Service’s features or user interface, or solicit users or
            customers from the Service;
          </li>

          <br />

          <li>
            further or promote any criminal activity or enterprise or provide
            instructional information about illegal activities; or
          </li>

          <br />

          <li>
            obtain or attempt to access or otherwise obtain any materials or
            information through any means not intentionally made available or
            provided for through the Service.
          </li>
        </ol>
      </p>

      <h3>
        <b>Commercial Use:</b>
      </h3>

      <p>
        Unless otherwise expressly authorized herein or in the Service, you
        agree not to display, distribute, license, perform, publish, reproduce,
        duplicate, copy, create derivative works from, modify, sell, resell,
        exploit, transfer or upload for any commercial purposes, any portion of
        the Service, use of the Service, or access to the Service. The Service
        is for your personal use.
      </p>

      <h3>
        <b>Indemnity and Release</b>
      </h3>

      <p>
        You agree to release, indemnify and hold Qrios and its affiliates and
        their officers, employees, directors and agents (collectively,
        “Indemnitees”) harmless from any from any and all losses, damages,
        expenses, including reasonable attorneys’ fees, rights, claims, actions
        of any kind and injury (including death) arising out of or relating to
        your use of the Service, any User Content, your connection to the
        Service, your violation of these Terms of Service or your violation of
        any rights of another. Notwithstanding the foregoing, you will have no
        obligation to indemnify or hold harmless any Indemnitee from or against
        any liability, losses, damages or expenses incurred as a result of any
        action or inaction of such Indemnitee. If you are a California resident,
        you waive California Civil Code Section 1542, which says: “A general
        release does not extend to claims which the creditor does not know or
        suspect to exist in his favor at the time of executing the release,
        which if known by him must have materially affected his settlement with
        the debtor.” If you are a resident of another jurisdiction, you waive
        any comparable statute or doctrine.
      </p>

      <h3>
        <b>Disclaimer of Warranties</b>
      </h3>

      <p>
        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
        AN “AS IS” AND “AS AVAILABLE” BASIS. Qrios EXPRESSLY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
        INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
      </p>

      <p>
        Qrios MAKES NO WARRANTY THAT
        <ol type="i">
          <li>THE SERVICE WILL MEET YOUR REQUIREMENTS</li>
          <li>
            THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
          </li>
          <li>
            THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE
            ACCURATE OR RELIABLE
          </li>
          <li>
            THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET
            YOUR EXPECTATIONS.
          </li>
        </ol>
      </p>

      <h3>
        <b>Changes to This Terms and Conditions</b>
      </h3>

      <p>
        We may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms and Conditions on this page.
      </p>

      <p>These terms and conditions are effective as of 2021-03-01</p>

      <h3>
        <b>Contact Us</b>
      </h3>

      <p>
        If you have any questions or suggestions about my Terms and Conditions,
        do not hesitate to contact us at{" "}
        <a href="mailto:hello@qriosapp.com">hello@qriosapp.com</a>
      </p>
    </div>
  );
}
