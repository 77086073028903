import React, { useState } from 'react'
// import Header from './Header'
// import Footer from './Footer';
// import screenShot1 from './assets/screenshots/screenshot1.jpg'
// import screenShot2 from './assets/screenshots/screenshot2.jpg'
// import screenShot3 from './assets/screenshots/screenshot3.jpg'

import androidApp from '../src/assets/google_play.svg'
import iosApp from '../src/assets/ios_app.svg'
import relationShip from '../src/assets/download.svg'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function QriosAppDetails() {

  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const steps = getSteps();

  function getSteps() {
    return ['Signup for Qrios', 'Create a new game instance', 'Enjoy the game & leave your feedback', 'Next up...'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return `Just create an account & verify your email address`;
      case 1:
        return 'Login to the account and create a new game play and share the game code with your friend & invite him/her';
      case 2:
        return `Both you and your friend can now play the Curiosity game & leave us your valuable feedback.`;
      case 3:
        return `We are working on creating more interactive games to enhance relationship building.. Until then, download our app from the App Store / Play Store.`;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

    return (
      
    <div className="container">

        <center>
          <img src={relationShip} style={{border: 2, borderRadius: 30, width: "45em"}} className="img-fluid hover-shadow" alt="logo" />

          <div style={{textAlign: 'center', padding: '3em'}}>
            <h1 style={{fontFamily: 'Mukta'}}>An emotional intelligence & <br/> authentic relationship building game</h1>
            <h2 style={{color: "#808d9f"}}>Qrios improves your emotional intelligence and relationships through guided interactions with the Qrios users</h2>
          </div>

          <div style={{flex: 2, flexFlow: 'row'}}>
          <h4 style={{fontFamily: 'Mukta'}}>Download the App Now:</h4>

            <a rel="noreferrer" target={'_blank'} href='https://play.google.com/store/apps/details?id=com.qriosmobilev1.test'>
              <img src={androidApp} alt="logo" className="hover-shadow" style={{margin: 5}}/>
            </a>

            <a rel="noreferrer" target={'_blank'} href='https://apps.apple.com/ca/app/qrios/id6470128188'>
              <img src={iosApp} alt="logo" className="hover-shadow" />
            </a>

          </div>
        </center>

        <div className="col-md-12" style={{marginTop: "2em"}}>

          <center>
            
            <h4 style={{ fontFamily: 'monospace', color: 'sienna'}}><b>Why play Qrios ?</b></h4>

            <div className="row" style={{ paddingTop: '1em' }}>

              <div className="col-md-4">
                <div style={{margin: '0.25em'}}>
                <Chip label="Authentic Relationship Skills" color="primary" className="hover-shadow"/>
                </div>
              </div>

              <div className="col-md-4">
                <div style={{margin: '0.25em'}}>
                <Chip label="Mindfulness" color="primary" className="hover-shadow"/>
                </div>
              </div>

              <div className="col-md-4">
                <div style={{margin: '0.25em'}}>
                <Chip label="Emotional Intelligence" color="primary" className="hover-shadow"/>
                </div>
              </div>

            </div>

          </center>
         
          <Timeline align="alternate" style={{marginTop: '2em', marginBottom: '2em'}}>

            <TimelineItem>
              <TimelineSeparator>
              <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography>
                  <b>Learn</b> new skills with educational video modules
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
            <TimelineSeparator>
            <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography style={{textAlign: 'left'}}>
                  <b>Practice</b> with live, guided video interactions
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography>
                  <b>Connect</b> and chat with the Qrios users
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

          
          </Timeline>

        </div>

        <div className="col-md-12" style={{marginTop: "1em"}}>
        
        <center><h4 style={{ fontFamily: 'inherit', color: 'teal', marginBottom: '2em'}}><b>How to become a Qrios player ?</b></h4></center>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                {activeStep === steps.length - 1 ? (<p style={{marginTop: '0.5em'}}><a href="/try"><button type="button" className="btn btn-primary" style={{borderRadius: 30}}>Try for Free</button></a></p>) : null }
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}

      </div>

        </div>
    )
}
