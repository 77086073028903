import React from 'react'
import qrioslogo from '../src/assets/qrios180X180.png'

export default function Footer() {
    return (

<div style={{ backgroundColor: "#f1f1f1" }}>

  <hr/>

    <div className="container col-md-12">

      <div className="row">

        <div className="col-md-8 text-md-start mb-4">

          <h5 className="text-uppercase fw-bold" style={{fontFamily: 'Mukta'}}>
            <a href="/">
            <img src={qrioslogo} className="App-logo" alt="logo" style={{width: "2em", height: "2em", marginRight: "0.5em" }} />
            </a>
            Qrios
          </h5>

          <p>
            Human Connection <br/>
            © {new Date().getFullYear()} Copyright <a className="text-dark" href="https://qriosapp.com/"> Qrios App</a>
          </p>

          <section>

            <a
              className="btn btn-link btn-floating btn-lg text-dark"
              href="#!"
              role="button"
              ><i className="fab fa-facebook-f"></i
            ></a>

            <a
              className="btn btn-link btn-floating btn-lg text-dark"
              href="#!"
              role="button"
              ><i className="fab fa-twitter"></i
            ></a>

            <a
              className="btn btn-link btn-floating btn-lg text-dark"
              href="#!"
              role="button"
              ><i className="fab fa-instagram"></i
            ></a>

          </section>

        </div>

        <div className="col-md-2">
          <p>
            <a href="/privacy" className="text-reset">Privacy Policy</a>
          </p>
          <p>
            <a href="/termsofservice" className="text-reset">Terms of Service</a>
          </p>
          <p>
          <a href="mailto:hello@qriosapp.com" className="text-reset">Email Us</a>
          </p>
        </div>

        <div className="col-md-2">
          <p>
            <a href="https://testflight.apple.com/join/zHn70h7S" rel="noreferrer" target="_blank" className="text-reset">iOS App</a>
          </p>
          <p>
            <a href="https://play.google.com/apps/testing/com.qriosmobilev1.test" rel="noreferrer" target="_blank" className="text-reset">Android App</a>
          </p>
        </div>

      </div>
      
    </div>

</div>

    )
}
