import React from 'react'
import androidApp from '../src/assets/google_play.svg'
import iosApp from '../src/assets/ios_app.svg'

export default function Header() {
    return (

    <div className="container">

      <div style={{margin: '10% 10%'}}>

      <div className="d-flex flex-row App-header" style={{fontFamily: 'Mukta'}}>

        {/* <div><a href="/">
          <img src={qrioslogo} className="App-logo img-fluid hover-shadow" alt="logo" /></a>
        </div> */}

        <div style={{textAlign: 'center'}}>
            <h1 style={{color: 'black'}}>Qrios</h1>
            <h5>Human Connection</h5>
        </div>

      </div>

      <center>

      <h3 style={{fontFamily: 'Covered By Your Grace'}}>Available on</h3>

        <div style={{flex: 2, flexFlow: 'row', marginBottom: '1em'}}>
          <a rel="noreferrer" target={'_blank'} href='https://play.google.com/store/apps/details?id=com.qriosmobilev1.test'>
            <img src={androidApp} alt="logo" style={{margin: 5}}/>
          </a>
          <a rel="noreferrer" target={'_blank'} href='https://apps.apple.com/ca/app/qrios/id6470128188'>
            <img src={iosApp} alt="logo" />
          </a>
        </div>

        <h4 style={{fontFamily: 'Covered By Your Grace'}}>Web Version coming soon...</h4>

      </center>
      
      </div>
      
    </div>

    )
}
